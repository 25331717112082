.btn {
  @apply !outline-none;
  @apply border border-solid border-transparent;

  border-radius: 8px;
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  gap: 5px;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  @apply opacity-40;
  pointer-events: none;
  touch-action: none;
}

.btn:hover {
  color: var(--text-button-hover-color);
}

.btn.active {
  box-shadow: none;
}

.btn.btn-primary {
  @apply text-white bg-blue-8 border-blue-8;
  @apply hover:text-white hover:bg-blue-9 hover:border-blue-9;
  @apply th-dark:hover:bg-blue-7 th-dark:hover:border-blue-7;
}

.btn.btn-primary:active,
.btn.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
  @apply bg-blue-9 border-blue-5;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  @apply bg-blue-8;
}

/* Button Secondary */
.btn.btn-secondary {
  @apply border border-solid;

  @apply text-blue-9 bg-blue-2 border-blue-8;
  @apply hover:bg-blue-3;

  @apply th-dark:text-blue-3 th-dark:bg-gray-10 th-dark:border-blue-7;
  @apply th-dark:hover:bg-blue-11;
}

.btn.btn-danger {
  @apply bg-error-8 border-error-8;
  @apply hover:bg-error-7 hover:border-error-7 hover:text-white;
}

.btn.btn-danger:active,
.btn.btn-danger.active,
.open > .dropdown-toggle.btn-danger {
  @apply bg-error-8 text-white border-blue-5;
}

.btn.btn-dangerlight {
  @apply text-error-9 th-dark:text-white;
  @apply bg-error-3 th-dark:bg-error-9;
  @apply hover:bg-error-2 th-dark:hover:bg-error-11;
  @apply border-error-5 th-dark:border-error-7 th-highcontrast:border-error-7;
  @apply border border-solid;
}

.btn.btn-success {
  background-color: var(--ui-success-7);
}

.btn.btn-success:hover {
  color: var(--white-color);
}

/* secondary-grey */
.btn.btn-default,
.btn.btn-light {
  @apply bg-white border-gray-5 text-gray-9;
  @apply hover:bg-gray-3 hover:border-gray-5 hover:text-gray-10;

  /* dark mode */
  @apply th-dark:bg-gray-warm-10 th-dark:border-gray-warm-7 th-dark:text-gray-warm-4;
  @apply th-dark:hover:bg-gray-warm-9 th-dark:hover:border-gray-6 th-dark:hover:text-gray-warm-4;

  @apply th-highcontrast:bg-black th-highcontrast:border-gray-2 th-highcontrast:text-white;
  @apply th-highcontrast:hover:bg-gray-9 th-highcontrast:hover:border-gray-6 th-highcontrast:hover:text-gray-warm-4;
}

.btn.btn-light:active,
.btn.btn-light.active,
.open > .dropdown-toggle.btn-light {
  background-color: var(--ui-gray-3);
}

.btn.btn-link {
  @apply text-blue-8 hover:text-blue-9 disabled:text-gray-5;
  @apply th-dark:text-blue-8 th-dark:hover:text-blue-7;
  @apply th-highcontrast:text-blue-8 th-highcontrast:hover:text-blue-7;
}

.btn-group {
  display: inline-flex;
}

.input-group-btn .btn.active,
.btn-group .btn.active {
  @apply bg-blue-2 text-blue-10 border-blue-5;
  @apply th-dark:bg-blue-11 th-dark:text-blue-2 th-dark:border-blue-9;
}

/* focus */

.btn.btn-primary:focus,
.btn.btn-secondary:focus,
.btn.btn-light:focus {
  @apply border-blue-5;
}

.btn.btn-danger:focus,
.btn.btn-dangerlight:focus {
  @apply border-blue-6;
}

.btn.btn-primary:focus,
.btn.btn-secondary:focus,
.btn.btn-light:focus,
.btn.btn-danger:focus,
.btn.btn-dangerlight:focus {
  --btn-focus-color: var(--ui-blue-3);
  box-shadow: 0px 0px 0px 4px var(--btn-focus-color);
}

[theme='dark'] .btn.btn-primary:focus,
[theme='dark'] .btn.btn-secondary:focus,
[theme='dark'] .btn.btn-light:focus,
[theme='dark'] .btn.btn-danger:focus,
[theme='dark'] .btn.btn-dangerlight:focus {
  --btn-focus-color: var(--ui-blue-11);
}

a.no-link,
a[ng-click] {
  @apply text-current;
  @apply hover:no-underline hover:text-current;
  @apply focus:no-underline focus:text-current;
}

a,
a.hyperlink {
  @apply text-blue-8 hover:text-blue-9;
  @apply hover:underline cursor-pointer;
}
